body {
  overflow: hidden;
}
.btn:focus {
  box-shadow: none;
}
.cursor-pointer {
  cursor: pointer;
}
.copy-to-clipboard {
  cursor: pointer;
}
.y-mandatory {
  scroll-snap-type: y mandatory;
}
.location_without_border {
  font-size: 2rem;
    margin-top: -15px;
    margin-bottom: -13px;
    align-items: center;
    display: flex;
}
.scroll-snap-start {
  scroll-snap-align: start;
}
.App {
  display: flex;
  height:  100%;
  position:  absolute;
  width:  100%;
}

.QuickReply{
  white-space: pre-line;
  cursor: pointer;
  background: #3498db;
  color: white;
  border-radius: 5px;
  margin: 3px;
  padding: 3px;
  font-size: 10px;
}