.MessageBox {
	background-color: #f3f3f3;
	height: calc(100vh - 50px - 100px - 70px);
}
.MessageBoxScroll {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	padding: 10px;
}
.Message {
	/*font-size: 1rem;*/
	padding: 5px 10px;
	border-radius: 5px;
	background-color: #fff;
	/*margin-bottom: 15px;*/
	display: flex;
	/*max-width: 70%;*/
	box-shadow: 1px 1px 3px #ccc;
	align-self: flex-start;
	position: relative;
}
.Message .plain {
	white-space: pre-line;
}
.Message2 {
	/*font-size: 1rem;*/
	/*padding: 5px 10px;*/
	/*border-radius: 5px;*/
	/*background-color: #fff;*/
	margin-bottom: 15px;
	display: flex;
	max-width: 70%;
	/*box-shadow: 1px 1px 3px #ccc;*/
	align-self: flex-start;
	position: relative;
}
.Message.call-log {
	align-self: center;
}
.Message.mine {
	background-color: lightgreen;
	align-self: flex-end;
}
.Message2.mine {
	align-self: flex-end;
}
.Message2 .message-time {
	position: absolute;
	left: 110%;
	left: calc(100% + 10px);
}
.Message2.mine .message-time {
	right: 110%;
	right: calc(100% + 10px);
	left: auto;
}